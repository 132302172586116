import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Principal } from '@sod/core/models';
import { STATE_TOKEN } from '@sod/store/app.state.model';
import { filter, Subject, switchMap, map } from 'rxjs';

@Component({
  standalone: true,
  selector: 'sod-wrapper-logo',
  templateUrl: './wrapper-logo.component.html',
  styleUrl: './wrapper-logo.component.scss'
})
export class WrapperLogoComponent {
  private navigate = new Subject<void>();

  constructor(private router: Router, private store: Store) {
    this.navigate.pipe(switchMap(() => this.store.select(STATE_TOKEN)),
      map(x => (!!x?.token && (new Principal(x.token)).TokenValid())? x : null),
      map(x => x?.site || 'login'),
      switchMap(site => this.router.navigate([site]))
    ).subscribe()

  }
  logoClicked(e: MouseEvent) {
    e.preventDefault();
    this.navigate.next();
  }

}
